type Query = {
  enabled: MaybeRefOrGetter<boolean>;
  suspense: () => Promise<unknown>;
};

/**
 * Wrap suspense invocation to ensure we never end up with a promise that never resolves
 */
export function useSafeQuerySuspense({ enabled, suspense }: Query) {
  const runSuspense = async () => {
    if (toValue(enabled)) {
      return suspense();
    }
  };
  onServerPrefetch(runSuspense);

  return runSuspense();
}
